<template>
  <div>
    <loading-spinner v-if="loading"></loading-spinner>
    <div v-else-if="userIndications.length > 0" class="main-indications">
      <div class="header-indications">
        <h4>
          Total recebido pelas indicações:
          {{
            new Intl.NumberFormat("pt-BR", {
              style: "currency",
              currency: "BRL",
            }).format(totalValidIndicationsAmount)
          }}
        </h4>
        <section class="section-filter-indications">
          <button
            class="btn-filter-indications paid"
            :class="{ active: selectedFilter === 'paid' }"
            @click="filterIndications(true, 'paid')"
          >
            Recebidas
          </button>
          <button
            class="btn-filter-indications unpaid"
            :class="{ active: selectedFilter === 'unpaid' }"
            @click="filterIndications(false, 'unpaid')"
          >
            A receber
          </button>
        </section>
      </div>
      <div class="content-indications">
        <div
          class="indication-card"
          v-for="(indication, index) in filteredIndications"
          :key="index"
        >
          <div class="indication-card-title">
            Usuário indicado: <b>{{ indication.name }}</b>
          </div>
          <div class="indication-card-body">
            <b>Descrição:</b>
            <span>
              {{ indication.payment_status_description }}
            </span>
          </div>
          <div class="indication-card-footer">
            <div>
              <span
                :class="badgeClass(indication.payment_status_id)"
                v-text="textStatus(indication.payment_status_id)"
              />
            </div>
            <div>
              Valor:
              <b>
                {{
                  new Intl.NumberFormat("pt-BR", {
                    style: "currency",
                    currency: "BRL",
                  }).format(indication.amount_to_receive)
                }}
              </b>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="none-indications" v-else>
      <h4>Você ainda não indicou ninguém para participar da Inside</h4>
    </div>
  </div>
</template>

<script>
// import TextField from "@/components/Form/TextField/";
// import TextFieldMask from "@/components/Form/TextFieldMask/";
// import Alert from "@/components/Alert/";
import UserService from "@/api/services/user.js";
import Loading from "@/components/Loading/Loading.vue";

export default {
  components: {
    // TextField,
    // TextFieldMask,
    // Alert,
    "loading-spinner": Loading,
  },
  mounted() {
    this.getUserIndications();
  },
  data() {
    return {
      loading: false,
      filterPaid: null,
      userIndications: [],
      filteredIndications: [],
      selectedFilter: "",
      color: "success",
      colorsByStatus: [
        { status: 1, value: "light-warning", text: "Processando" },
        { status: 2, value: "light-warning", text: "Processando" },
        { status: 3, value: "light-warning", text: "Processando" },
        { status: 4, value: "success", text: "Pago" },
        { status: 5, value: "danger", text: "Cancelado" },
      ],
    };
  },
  computed: {
    totalValidIndicationsAmount() {
      if (this.userIndications.length === 0) return 0;

      return this.userIndications
        .filter((indication) => indication.payment_status_id === 4)
        .reduce((acc, curr) => (acc += Number(curr.amount_to_receive)), 0);
    },
  },
  methods: {
    badgeClass(status) {
      const badgeClass = `badge badge-${
        this.colorsByStatus.find((c) => c.status === status)?.value || "info"
      }`;

      return `${badgeClass}`;
    },
    textStatus(status) {
      console.log("🚀 ~ textStatus ~ status:", status);
      const text = `${
        this.colorsByStatus.find((c) => c.status === status)?.text
      }`;

      return `${text}`;
    },
    filterIndications(paid, filter) {
      this.filterPaid = this.filterPaid === paid ? null : paid;

      this.selectedFilter = this.selectedFilter === filter ? "" : filter;
      if (this.filterPaid === null) {
        this.filteredIndications = this.userIndications;
      } else {
        this.filteredIndications = this.userIndications.filter(
          (indication) => indication.paid_friendly_invitation === paid
        );
      }
    },
    // badgeClass(status = "Pendente") {
    //   const badgeClass = `badge badge-${
    //     this.colorsByStatus.find((c) => c.title === status)?.value || "info"
    //   }`;

    //   return `${badgeClass}`;
    // },
    async getUserIndications() {
      try {
        this.loading = true;

        const response = await UserService.getUserIndications();
        console.log("🚀 ~ getUserIndications ~ response:", response);

        this.userIndications = response.data;
        this.filteredIndications = response.data;
      } catch (error) {
        return error;
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped>
.main {
  width: 100%;
  background-color: aliceblue;
}
.main-indications {
  border-radius: 4px;
  margin: 1em;
}

.header-indications {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.section-filter-indications {
  display: flex;
  width: 170px;
  justify-content: space-between;
  align-items: center;
}
.content-indications {
  width: 100%;
  display: block;
  justify-content: center;
}

.indication-card {
  margin-top: 12px;
  padding: 16px 16px 0 16px;
  background-color: #fafafa;
  border-radius: 5px;
  width: 98%;
}
.btn-filter-indications {
  padding: 2px 6px;
  background-color: #17c964;
  border-radius: 5px;
  color: white;
  font-weight: bold;
  border: none;
  height: 30px;
}
.paid {
  background-color: #17c964;
}
.paid.active {
  border: 2px solid #138946;
}
.paid:hover {
  background-color: #13a450;
}
.unpaid {
  background-color: #ffc107;
}
.unpaid.active {
  border: 2px solid #b2880a;
}
.unpaid:hover {
  background-color: #d19e05;
}
.indication-card-title {
  font-size: 16px;
  height: 30px;
}
.indication-card-body {
  height: 60px;
}
.indication-card-body > span {
  font-style: italic;
  color: black;
}
.indication-card-footer {
  display: flex;
  justify-content: space-between;
  height: 40px;
}

.none-indications {
  text-align: center;
  font-size: 16px;
  color: gray;
}

.pa-5 {
  padding: 2em;
}
.button {
  margin-top: 2em;
}

@media (max-width: 1200px) {
  .content-indications {
    grid-template-columns: 1fr 1fr;
  }

  .indication-card {
    width: 90%;
  }

  .indication-card-body {
    width: 90%;
  }
}

@media (max-width: 800px) {
  .content-indications {
    grid-template-columns: 1fr;
  }

  .indication-card {
    width: 90%;
  }

  .indication-card-body {
    width: 90%;
  }
}
</style>
